import { gql } from '@apollo/client';

export const OFFICE_NEWS_QUERY = gql`
  query GetAllNews($input: GetAllNewsInput!) {
    getAllNews(input: $input) {
      total
      list {
        title
        ingress
        slug
        image
      }
    }
  }
`;
