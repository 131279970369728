import * as React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { useForm } from '../../../../utils/hooks/useForm';
import { Form, FormRow } from '../../../ui/form/Form';
import { FormError } from '../../../ui/form/FormError';
import { Input } from '../../../ui/form/Input';
import { Button } from '../../../ui/button/Button';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { Section } from '../../../layout/Section';
import { ButtonLink } from '../../../ui/link/ButtonLink';
import { createLeadMutation } from '../../../../../client/__graphql__/mutations';

import { SiteContext } from '../../../../utils/context/SiteContext';

import { ILeadInput, ILeadResponse } from '../../../../__types__/global';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { useLocation } from 'react-router';
import { DiggerType, useDiggerId } from '../../../../utils/hooks/useDiggerId';

export const FormValuation: React.FC<{ urltag: string }> = ({ urltag }) => {
  const location = useLocation();
  const { routeConfig }: any = React.useContext(SiteContext);
  const { submitWithDigger } = useDiggerId();

  const [posted, setPosted] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const VALUATION_MUTATION = createLeadMutation({
    valuation: {
      response: `{
        success
      }`
    }
  });

  const [lead, { error }] = useMutation<ILeadResponse, ILeadInput>(
    VALUATION_MUTATION,
    {
      onError: ({ graphQLErrors, networkError }) => {
        console.log(graphQLErrors, networkError);
      },
      onCompleted: ({ lead }) => {
        setLoading(false);
        if (lead.valuation?.success) {
          setPosted(true);
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      setLoading(true);

      submitWithDigger(
        DiggerType.VERDIVURDERING,
        {
          zip: preparedFields.zip
        },
        (data) => {
          lead({
            variables: {
              input: {
                ...preparedFields,
                diggerId: data.diggerId,
                referer: location.pathname,
                identifier: urltag,
                leadType: 'VERDIVURDERING',
                pageType: 'KONTOR'
              }
            }
          });
        }
      );
    }
  });
  return (
    <FormWrapper>
      <Section className="form-section">
        {posted ? (
          <div style={{ marginTop: '40px' }}>
            <Heading tag="h2" center={true}>
              Takk for din henvendelse
            </Heading>
          </div>
        ) : (
          <>
            <Heading tag="h1" center={true}>
              Tilbud på eiendomssalg?
            </Heading>
            <Paragraph center={true}>
              Skal du selge eiendom bør du ikke legge den for salg før du har
              mottatt et tilbud fra en av våre eiendomsmeglere. Legg igjen
              kontaktinformasjon. Vi gir deg med glede et uforpliktende tilbud.
            </Paragraph>
            <Form onSubmit={handleSubmit} noValidate>
              {error &&
                error.graphQLErrors.map((error, index) => (
                  <FormError key={`error_${index}`} message={error.message} />
                ))}
              {fields.map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
              <ButtonGroup>
                <Button
                  type="primary"
                  colorTheme="gold"
                  disabled={loading}
                  loading={loading}
                >
                  Send
                </Button>
                <ButtonLink
                  as="a"
                  href={routeConfig.personvern.url}
                  className="privacy-link-mobile"
                >
                  Personvernpolicy
                </ButtonLink>
              </ButtonGroup>
            </Form>
          </>
        )}
      </Section>
    </FormWrapper>
  );
};

const FormWrapper = styled.div``;
